<template>
    <div class="box" id="top">
        <div class="blog-filter">
            <div :class="['blog-filter-item', {active : filterSelected == 1}]" @click="blogFilter([1,2,3,4], 1)">{{$t('news.all')}}</div>
            <div :class="['blog-filter-item', {active : filterSelected == 2}]" @click="blogFilter([1], 2)">{{$t('news.news')}}</div>
            <div :class="['blog-filter-item', {active : filterSelected == 3}]" @click="blogFilter([2], 3)">{{$t('news.events')}}</div>
            <div :class="['blog-filter-item', {active : filterSelected == 4}]" @click="blogFilter([3], 4)">{{$t('news.pressRelease')}}</div>
            <div :class="['blog-filter-item', {active : filterSelected == 5}]" @click="blogFilter([4], 5)">{{$t('news.pressReview')}}</div>
        </div>
        <div class="blog-select">
            <select>
                <option :class="[{active : filterSelected == 1}]" @click="blogFilter([1,2,3,4], 1)">{{$t('news.all')}}</option>
                <option :class="[{active : filterSelected == 2}]" @click="blogFilter([1], 2)">{{$t('news.news')}}</option>
                <option :class="[{active : filterSelected == 3}]" @click="blogFilter([2], 3)">{{$t('news.events')}}</option>
                <option :class="[{active : filterSelected == 4}]" @click="blogFilter([3], 4)">{{$t('news.pressRelease')}}</option>
                <option :class="[ {active : filterSelected == 5}]" @click="blogFilter([4], 5)">{{$t('news.pressReview')}}</option>
            </select>
        </div>
        <div class="blog-filter-sep"></div>
        <div class="blog-container" v-if="postsFilter && postsFilter.length > 0">            
            <div class="post-item" v-for="(item, idx) in postsFilter" :key="idx">
                <div>
                    <div class="img-container">
                        <img :src="`${$store.state.assetsUrl}/front-${item.imgFeatured}`" class="img-thumb" />
                        <div class="post-date">{{ new Date(item.published).toLocaleDateString($i18n.locale.toUpperCase() + '-' + $i18n.locale, { year: 'numeric', month: 'long', day: 'numeric' })}}</div>
                        <div class="post-type">{{ getType(item.cat)}}</div>
                    </div>
                    <h3>{{item.title}}</h3>
                    <div class="btn-container">
                        <div class="btn" @click="openPost(item)">{{$t("news.btn")}}</div>
                    </div>
                </div>
            </div>            
        </div>
        <pagination 
                v-if="pages"
                :pages="pages"
                :currentPage="currentPage"
                v-on:setPage="setPage"    
            ></pagination>
        <no-posts v-else></no-posts>
    </div>
</template>

<script>
import NoPosts from "./NoPosts.vue";
import axios from "axios";
import Pagination from "./Pagination.vue";

export default {
    name : "BlogFilter",
    components : {
        NoPosts,
        Pagination
    },
    data() {
        return {
            posts : [],
            currentPage : 1,
            pages : null,
            cats : [1,2,3,4],
            filterSelected : 1
        }
    },
    computed : {
        postsFilter : function() {
            return this.posts;
        }
    },
    mounted() {
        this.getData(this.cats);
    },
    methods : {
        getType(cat) {
            let typeStr = "";
            switch(cat) {
                case 1 : 
                    typeStr = this.$t("news.news");
                    break;
                case 2 : 
                    typeStr = this.$t("news.events");
                    break;
                case 3 : 
                    typeStr = this.$t("news.pressRelease");
                    break;
                case 4 : 
                    typeStr = this.$t("news.pressReview");
                    break;
                case 5 : 
                    typeStr = this.$t("news.career");
                    break;
                case 6 : 
                    typeStr = this.$t("news.docs");
                    break;
                case 7 : 
                    typeStr = this.$t("news.shareholdersMeeting");
                    break;
            }
            return typeStr;
        },
        getData(cats) {
            let config = {
                validateStatus: () => true,  
            };
            let vm = this;  

            let catStr = ""
            cats.forEach(element => {
                catStr += element + "&";
            });

            catStr = catStr.substring(0, catStr.length -1);

            axios
                .get(`${this.$store.state.apiUrl}/public/posts/1/${catStr}/${this.$i18n.locale}/${this.currentPage}`,  config)
                .then(function (res) {                
                    if(res.status != 200) {
                        console.log('Error => ' + res.data.errors)                  
                    }else{
                        vm.posts = res.data.message;
                        vm.currentPage = res.data.pager.currentPage;
                        vm.pages = res.data.pager.totalPages;
                    
                    }
                });
        },
        openPost(post) {
            //si standar ou verture de la page puis populated
            if(post.type == 1) {
                this.$router.push({name : "BlogItem", params : { post : post.id}});
            }
            //si lien dof pdf on le DL
            else if(post.type == 2) {
                window.open(`${this.$store.state.apiUrl}/public/pdfs/${post.pdf}`, '_blank');
            }
            //sinon lien externe
            else {
                window.open(post.link, "_blank");
            }
        },
        setPage(page) {
            this.currentPage = page;
            this.getData(this.cats);
             var element = document.getElementById("top");
            var top = element.offsetTop - 100;
            window.scrollTo({top : top, behavior: 'smooth'});
        },
        blogFilter(filter, selected) {
            this.filterSelected = selected;
            this.cats = filter;
            this.getData(filter);
        }
    },
    watch : {
        "$i18n.locale" : function() {
            this.getData(this.cats);
        }
    }
}
</script>

<style lang="scss" scoped>
    .post-item {
        width:31%;
        margin:40px 1%;
    }

    .img-container {
        position: relative;
        width:100%;
    }

    .img-container img {
        max-width: 100%;
        transition: 0.5s;
        width: 100%;
    }

    .post-type {
        position:absolute;
        top:0;
        left:0;
        padding:10px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        color:#fff;
        font-size:15px;
        font-weight: 300;
        background:rgba($mainColor, 0.8);
        text-align:center;
    }

    .post-date {
        color:$mainColor;
        font-size:15px;
        font-weight:300;
        margin:0;
    }

    h3 {
        color:$black;
        font-size:18px;
        font-weight:400;
        text-align: left;
    }

    .btn-container{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .btn-container .btn {
        background:#fff;
        color:$mainColor;
        border:2px solid $mainColor;
        font-size:18px;
    }

    .btn-container .btn:hover {
        background:$mainColor;
        color:#fff;
    }

    .blog-container {
        display: flex;
        flex-wrap: wrap;
    }

    .blog-filter {
        width:100%;
        margin:0 auto;
        display: flex;
    }

    .blog-filter-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width:calc(20% - 4px);
        margin:0 2px;
        background:#f1f1f1;
        color:$mainColor;
        cursor: pointer;
        font-size:20px;
        font-weight:400;
        height: 60px;
        transition: 0.5s;
        text-align: center;
    }

    .blog-filter-item.active, .blog-filter-item:hover {
        background:$mainColor;
        color:#fff;
    }

    .blog-filter-sep {
        height:4px;
        width:calc(100% - 4px);
        background: $mainColor;
        margin:0 auto;
    }

    .blog-select {
        display: none;
    }

    select {
        background:$mainColor;
        color:#fff;
        width:250px;
    }

    option {
        font-size: 18px;
        background-color: #f1f1f1;
        color:$mainColor;
    }


    @media screen and (max-width:1024px) {
        .blog-filter-item {
            font-size:18px;
            height:40px;
        }

        .post-item {
            width:48%;
        }
    }

    @media screen and (max-width:1024px) {
        .post-item {
            width:98%;
        }

        .blog-select {
            display: flex;
            justify-content: center;
        }

        .blog-filter, .blog-filter-sep {
            display: none;
        }
    }

</style>