<template>  
    <section class="banner">
      <div class="row">
        <div class="col-40 col-banner-title">
          <h1 v-html="$t('home.title')"></h1>
          <h2 v-html="$t('home.title2')"></h2>
          <div class="btns-container">
            <a href="https://www.safeorthopaedics.com" target="_blank" class="btn-banner">Safe Orthopaedics</a>
            <a href="https://www.safemedical.fr" target="_blank" class="btn-banner">Safe Medical</a>
          </div>
          <p class="subtitle" v-html="$t('home.subtitle')"></p>
        </div>
        <div class="col-60 banner-img">
        </div>
      </div>
      <div class="banner-sub">
        <p class="subtitle" v-html="$t('home.subtitle2')"></p>    
      </div>
    </section>
    <section class="blog-featured">
      <blog-home></blog-home>
    </section>
    <section class="blog-filter">
      <blog-filter></blog-filter>
    </section>
    <!-- SECTION WEBSITES -->
    <section class="websites-container">
      <div class="row box">
        <div class="col-50">
          <div class="website-item">
            <img src="./../assets/safeortho_logo_white.png" alt="safe orthopaedics" />
            <div class="icons-container">
              <a href="https://www.safeorthopaedics.com" target="_blank" class="website-link website-icon"></a>
              <a href="https://www.linkedin.com/company/safe-orthopaedics/?trk=tyah&trkInfo=clickedVertical:company,clickedEntityId:2581242,idx:2-1-2,tarId:1477033991052,tas:safe%20orthopaedics" target="_blank" class="linkedin-link website-icon"></a>
            </div>
          </div>
        </div>
        <div class="col-50">                
          <div class="website-item">
            <img src="./../assets/safemedical_logo_white.png" alt="safe orthopaedics" />
            <div class="icons-container">
              <a href="https://www.safemedical.fr" target="_blank" class="website-link website-icon"></a>
              <a href="https://www.linkedin.com/company/lci-medical/?originalSubdomain=fr" target="_blank" class="linkedin-link website-icon"></a>
            </div>
          </div>
        </div>
      </div>
    </section>  
</template>

<script>
// import BlogHome from "./../components/BlogHome.vue";
import BlogFilter from "./../components/BlogFilter.vue";

export default {
  name: 'Home',
  components : {
    // BlogHome,
    BlogFilter
  }
}
</script>

<style scoped lang="scss">

.banner-sub {
  background:$mainColor;
}


.banner h1 {
  color:$black;
  font-size:40px;
  font-weight:700;
}

.banner h2 {
  font-size:26px;
}

.banner p.subtitle {
  font-size:20px;
  text-align: left;
  color:$black;
}

.banner-sub p.subtitle {
  color:#fff;
  padding: 20px 5%;
}

.btns-container {
  display: flex;
  justify-content:space-between;
  margin:40px 0;
  width: 100%;
}

.btn-banner {
  width:calc(45% - 20px);
  padding:10px;
  background:$mainColor;
  color:#fff;
  font-size:100%;
  text-align: center;
  transition: 0.5s;
}

.btn-banner:hover {
  background: darken($mainColor, 10);
  text-decoration:none;
}

.banner-img {
  background-image: url("./../assets/banner-home.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 0;
  width: 59%;
  min-height: calc(100vh - 90px);
}

.col-banner-title {
  min-height: calc(100vh - 140px);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin:0 5%;
  width:30%;
  padding-top:50px;
  background-image: url("./../assets//safeogroup-filigram.jpg");
  background-position: center ;
  background-repeat: no-repeat;
  background-size:contain;
}


.websites-container {
  background:$black;
  padding:80px 0;
}

.blog-featured {
  margin-top:80px;
}

.blog-filter {
  margin:80px auto;
}

/* WEBSITES SECTION */

.websites-container .col-50 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.website-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width:90%;
}

.website-item img {
  max-width: 90%;
}

.icons-container {
  display: flex;
  justify-content: space-around;
  margin-top:40px;
}

.website-icon {
  width:60px;
  height:62px;
  margin:0 20px;
  cursor: pointer;
  transition:0.5s;
}

.website-link {
  background:url("./../assets/icon-site.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.linkedin-link {
  background:url("./../assets/icon-likedin.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width : 1250px){
  .col-banner-title {
    width:90%;
  }

  .banner-img {
    display: none;
  }
}

@media screen and (max-width : 768px){
  .btns-container {
    flex-direction: column;
    align-items: center;
  }

  .btn-banner {
    margin-bottom: 10px;
  }

}

</style>
